<template>
<b-container fluid>
  <b-row class="text-center mb-4" align-h="center" v-if="loading">
    <b-col cols="1">
      <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
    </b-col>
  </b-row>
  <b-row class="row text-white bg-kpmg-blue py-4">
    <b-col align-self="center" cols="2" class=" cols text-center" align-v="center">
      <h1 justify="center" align="center" class="kstyle title justify-content-md-center">Documentation</h1>
    </b-col>
    <b-col cols="8" align-self="center" align-v="center" class="search justify-content-md-center">
      <b-container>
        <b-row>
          <b-col>
            <b-input-group>
              <b-form-input v-model="filter" placeholder="Type to Search" />
                <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
            </b-input-group>
          </b-col>
        </b-row>
      </b-container>
    </b-col>
  </b-row>
  <b-row class="mb-4" v-if="!loading && permissions.add">
    <b-col>
      <b-button class="mr-2 my-4" variant="outline-primary" :to="{ name: 'FormNew', params: { model: 'suggestrule' }}">new</b-button>
    </b-col>
  </b-row>
  <b-row class="mb-4" v-if="!loading">
    <b-col>
      <strong>Rules {{rowsShow.length}}</strong>
      <div v-for="row in rowsShow" :key="row.id">
        <line-item
          :item="row"
          :username="user.username"
        />
      </div>
    </b-col>
    <b-col>
      <strong>Topics wihtout rules {{topicsEmpty.length}}</strong>
      <div v-for="row in topicsEmpty" :key="'emptytopic-' + row.id">
        <b-link :to="{ name: 'Topic', params: { id: row.id }}">{{row.name}}</b-link>
      </div>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'

import LineItem from '@/views/suggestrules/RuleSimple.vue'

export default {
  name: 'Reportingframework',
  components: {
    LineItem
  },
  computed: {
    rowsShow: function () {
      let rows = []
      rows = this.rows
      rows.forEach(row => {
        row.target = '------------'
        if (row.regtopic) {
          row.target = row.regtopic.name
        }
        if (row.tag) {
          row.target = row.tag.name
        }
        if (row.risk) {
          row.target = row.risk.name
        }
      })
      if (this.filter.length > 0) {
        rows = rows.filter(x => {
          const term = x.id + ' ' + x.name + ' ' + x.target
          return term.toUpperCase().match(this.filter.toUpperCase())
        })
      }
      rows = _.sortBy(rows, ['target'])
      return rows
    },
    topicsEmpty: function () {
      const results = this.topics.filter(topic => {
        return topic.suggestrules.length === 0
      })
      return results
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "Suggestrules"
    this.$stat.log({ page: 'suggestrules', action: 'open suggestrules' })
    this.permissions.add = ac.can(this.user.acgroups).createAny('suggestrule').granted
    this.load()
  },
  data () {
    const data = {
      filter: '',
      keywords: [],
      loading: true,
      permissions: {
        add: false,
        read: false
      },
      rows: [],
      topics: []
    }
    return data
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        const rows = await this.$Amplify.API.get('cosmos', '/suggestrules/all')
        const topics = await this.$Amplify.API.get('cosmos', '/topics/graph')
        this.topics = topics
        this.rows = rows
        console.log(this.rows)
        this.loading = false
      } catch (e) {
        this.$logger.warn('load ERROR: ', e)
      }
    }
  }
}
</script>

<style>
</style>
