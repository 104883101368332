<template>
    <b-container fluid>
      <b-row>
        <b-col>
          <div class="mb-2">
            <b-link class="mr-2" :to="{ name: 'Suggestrule', params: { id: item.id } }">{{item.target}}</b-link>
            <b-badge class="mr-2" v-if="item.active">ACTIVE</b-badge>
            <span class="mr-2" v-if="!item.active">--------</span>
            <b-link class="mr-2" :to="{ name: 'Suggestrule', params: { id: item.id } }">{{item.name}} - {{item.id}}</b-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'

export default {
  name: 'RuleSimple',
  props: ['item', 'username'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.permission.edit = ac.can(this.user.acgroups).updateAny('suggestrule').granted
  },
  data () {
    return {
      permission: {
        edit: false
      }
    }
  }
}
</script>

<style>
</style>
